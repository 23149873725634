import { Box, Circle, Flex, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { Avatar, Button, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { CalculatorIcon, CompanyIcon, SumIcon } from 'Tokens/Icons/Data';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { getBUDisclosureRequirementLink, getDisclosureRequirementLink } from 'utils/links';
import {
  GeneratedSummaryStatus,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  hasQuarterlyFrequency,
  MetricsTableData,
} from '..';
import { AggregatedQualitativeAnswers, SubsidiaryAssessmentsType } from '../AggregatedMetrics';
import { MetricDocumentationAttachments, MetricDocumentationNotes } from '../MetricsDocumentation';
import { AnswersSummarizer } from './AnswersSummarizer/AnswersSummarizer';
import {
  AttachmentBox,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  DatapointFieldsFragment_,
  GetMetricAnswerDocumentationByIdDocument_,
  GetSingleEsrsMetricAnswerDocument_,
  NoteHistory,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  QuestionType_Enum_,
  useGetMetricAnswerDocumentationByIdQuery,
  User,
  useUpsertMetricsAnswerMutation,
} from 'models';
import { MetricOwnerSelect } from '../InputTable/MetricOwnerSelect';
import { AddUserIcon, AIIcon, EditIcon, LinkIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { GeneratedAnswer } from '../MetricAI';
import { AggregatedMetric, CalculatedMetric } from 'Molecules/MetricTypeIcon';
import { DatapointSourceEnum } from '../../Requirement';
import { LastEditedBy } from '../MetricsTable/MetricsTableComponents/LastEdited/LastEdited';
import { getRowName } from '../MetricsTable/MetricsTableComponents/MetricRowTitle/MetricRowTitle.hooks';

enum AnswerSourceType {
  calculated = 'calculated',
  aggregated = 'aggregated',
  manual = 'manual',
  generated = 'generated',
  moreScope = 'moreScope',
}

export const DetailsCard = ({
  isChild = true,
  title,
  author,
  answer,
  subsidiary,
  metricRef,
  isAggregated,
  reportingUnitId,
  isGroup = true,
  choices,
  isNumeric,
}: {
  isChild?: boolean;
  title: string;
  author: string;
  answer?: string | null;
  subsidiary?: SubsidiaryAssessmentsType;
  metricRef?: string;
  isAggregated?: boolean;
  reportingUnitId?: string;
  isGroup?: boolean;
  choices: string[] | undefined;
  isNumeric?: boolean;
}) => {
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef,
    disclosureRequirementRef = '',
    drType = '',
  } = useParams();
  const [showMore, setShowMore] = useState(false);
  const numberOfLines = useMemo(() => Math.ceil((answer?.length ?? 1) / 48), [answer]);

  return (
    <VStack alignItems="start" spacing={isChild ? '4px' : '6px'} width="100%">
      <HStack spacing="16px" width="100%" justifyContent="space-between">
        <HStack gap="6px">
          {isChild ? <CompanyIcon /> : <SumIcon />}
          <TruncatableText variant="body" text={title} />
        </HStack>
        <HStack>
          {isNumeric && !(isAggregated && isGroup) && (
            <Typography variant="body" color={!!answer ? undefined : 'text.hint'}>
              {answer ?? 'N/A'}
            </Typography>
          )}
          <HStack
            spacing="4px"
            width={isNumeric ? '' : '100%'}
            style={isNumeric ? {} : { flex: '1', marginLeft: 'auto' }}
          >
            {!!author ? (
              <Avatar size="xs" name={author} />
            ) : (
              <Circle size="20px" bg="bg.unknown">
                <AddUserIcon boxSize="12px" color="text.muted" />
              </Circle>
            )}
            {isChild ? (
              <IconButton
                aria-label="click"
                size="sm"
                variant="ghost"
                icon={<ArrowUpRightIcon />}
                onClick={() =>
                  window.open(
                    isGroup
                      ? `${getDisclosureRequirementLink({
                          companyId: subsidiary?.company?.id,
                          assessmentId: subsidiary?.id,
                          standardRef,
                          disclosureRequirement: {
                            reference: disclosureRequirementRef,
                            type: drType,
                          },
                        })}/overview?metricRef=${metricRef}`
                      : `${getBUDisclosureRequirementLink({
                          companyId,
                          assessmentId: esrsAssessmentId,
                          businessUnitId: reportingUnitId ?? '',
                          standardRef,
                          disclosureRequirement: {
                            reference: disclosureRequirementRef,
                            type: drType,
                          },
                        })}/data-input?metricRef=${metricRef}`
                  )
                }
              />
            ) : (
              <HelpTooltip label="Use answers aggregated from subsidiaries to write your summary for this data point" />
            )}
          </HStack>
        </HStack>
      </HStack>
      {!isNumeric &&
        (!!answer || !!choices?.length ? (
          <>
            <VStack alignItems="start" width="305px">
              {choices?.length && (
                <Typography variant="body" width="305px">
                  {choices.join(', ')}
                </Typography>
              )}
              <Typography variant="body" noOfLines={showMore ? undefined : 5} width="305px">
                {answer}
              </Typography>
            </VStack>
            {numberOfLines > 5 && (
              <Button
                variant="ghost"
                size="sm"
                alignVertically={true}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Show less' : 'Show more'}
              </Button>
            )}
          </>
        ) : isChild ? (
          <Typography variant="body" color="text.hint">
            N/A
          </Typography>
        ) : null)}
    </VStack>
  );
};

const AnswerSourceTypeTag = ({
  type,
  calculation,
  isBusinessUnits,
  isQuarterly,
  isSubsidiaries,
  subMetrics,
}: {
  type: AnswerSourceType;
  calculation?: string;
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
}) => {
  const helpIconInFlex = (
    <Flex justifyContent="center">
      <HelpIcon color="inherit" opacity={0.4} />
    </Flex>
  );

  switch (type) {
    case AnswerSourceType.calculated:
      return (
        <Tag variant="undefined" size="xs" leftIcon={<CalculatorIcon />}>
          <HStack spacing="6px">
            <Typography variant="detailStrong">Calculated metric</Typography>
            <Tooltip
              maxW="340px"
              placement="left"
              shouldWrapChildren
              label={<CalculatedMetric calculation={calculation} subMetrics={subMetrics} />}
            >
              {helpIconInFlex}
            </Tooltip>
          </HStack>
        </Tag>
      );

    case AnswerSourceType.aggregated:
      return (
        <Tag variant="undefined" size="xs" leftIcon={<SumIcon />}>
          <HStack spacing="6px">
            <Typography variant="detailStrong">Aggregated metric</Typography>
            <Tooltip
              shouldWrapChildren
              maxW="340px"
              placement="left"
              label={
                <AggregatedMetric
                  isBusinessUnits={isBusinessUnits}
                  isQuarterly={isQuarterly}
                  isSubsidiaries={isSubsidiaries}
                  subMetrics={subMetrics}
                />
              }
            >
              {helpIconInFlex}
            </Tooltip>
          </HStack>
        </Tag>
      );

    // Manual
    case AnswerSourceType.manual:
      return (
        <Tag variant="undefined" size="xs" leftIcon={<EditIcon />}>
          <HStack spacing="6px">
            <Typography variant="detailStrong">Manual input</Typography>
            <Tooltip label="This data point is manually added by a person">
              {helpIconInFlex}
            </Tooltip>
          </HStack>
        </Tag>
      );

    case AnswerSourceType.generated:
      return (
        <VStack alignItems="start" spacing="4px">
          <Tag variant="info" size="xs" leftIcon={<AIIcon color="inherit" />}>
            <HStack spacing="6px">
              <Typography variant="detailStrong" color="inherit">
                AI-generated
              </Typography>
              <Tooltip
                shouldWrapChildren
                label="This data point is generated by AI and should be approved by a person "
              >
                {helpIconInFlex}
              </Tooltip>
            </HStack>
          </Tag>
          <Typography variant="detail" color="text.warning">
            Not approved by a person
          </Typography>
        </VStack>
      );

    case AnswerSourceType.moreScope:
      return (
        <Tag variant="undefined" size="xs" leftIcon={<LinkIcon color="inherit" />}>
          <HStack spacing="4px">
            <Typography variant="detailStrong" color="inherit" ml="4px">
              Integration
            </Typography>
            <Tooltip
              shouldWrapChildren
              label="This data point was ingested through an integration with MoreScope"
            >
              {helpIconInFlex}
            </Tooltip>
          </HStack>
        </Tag>
      );
  }
};

export const MetricSource = ({
  answerId,
  metric,
  companyReportingUnit,
  answerData,
  isGroup,
  username,
  selectedQuarter,
  esrsAssessmentProjectLeader,
  row,
  datapoint,
  isAggregated = false,
  generatedAnswer,
  generatedSummaryStatus,
  setGeneratedSummaryStatus,
}: {
  answerId: string;
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  answerData?: AggregatedQualitativeAnswers[number];
  isGroup: boolean;
  username: string;
  row?: MetricsTableData;
  selectedQuarter: string;
  esrsAssessmentProjectLeader?: Partial<User>;
  datapoint?: DatapointFieldsFragment_;
  isAggregated?: boolean;
  generatedAnswer?: GeneratedAnswer;
  generatedSummaryStatus?: GeneratedSummaryStatus;
  setGeneratedSummaryStatus?: React.Dispatch<React.SetStateAction<GeneratedSummaryStatus>>;
}) => {
  const isCalculated = useMemo(() => !!metric.calculation, [metric]);
  const [upsertAnswer] = useUpsertMetricsAnswerMutation();
  const { reportingUnitId = companyReportingUnit, esrsAssessmentId = '' } = useParams();
  const [metricAnswerId, setMetricAnswerId] = useState(answerId);
  const { data: documentationData, loading: documentationLoading } =
    useGetMetricAnswerDocumentationByIdQuery({
      variables: {
        metricAnswerId: metricAnswerId,
      },
      skip: !metricAnswerId,
    });

  useEffect(() => setMetricAnswerId(answerId), [answerId]);

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Answer_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.esrs_Answer_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );
  const isNumeric = useMemo(() => metric.metricType === QuestionType_Enum_.Decimal_, [metric]);

  // Function to get answer type (calculated, aggregated, AIGenerated, or manual)
  const answerSourceType = useMemo(() => {
    if (!!generatedAnswer && generatedAnswer.status === null) {
      return AnswerSourceType.generated;
    }
    if (datapoint?.source === DatapointSourceEnum.moreScope) {
      return AnswerSourceType.moreScope;
    }
    if (isCalculated) {
      return AnswerSourceType.calculated;
    }
    if (isAggregated) {
      return AnswerSourceType.aggregated;
    }
    return AnswerSourceType.manual;
  }, [isCalculated, isAggregated, generatedAnswer, datapoint]);

  const subMetrics = useMemo(() => {
    if (row) {
      return row.subRows?.map((subRow) => ({
        name: getRowName(subRow) ?? '',
        reference: subRow.metric.reference,
      }));
    }
  }, [row]);

  const calculation = useMemo(() => metric.calculation ?? undefined, [metric]);

  const isBusinessUnits = useMemo(() => hasChildOnBULevel(metric), [metric]);
  const isSubsidiaries = useMemo(() => hasChildOnSubLevel(metric), [metric]);
  const isQuarterly = useMemo(() => hasQuarterlyFrequency(metric), [metric]);
  const showAnswers = useMemo(() => {
    return (
      (!!answerData?.reportingUnits?.length ||
        !!answerData?.answer?.datapoints.length ||
        !!answerData?.subsidiaries?.length) &&
      (isSubsidiaries || isBusinessUnits)
    );
  }, [answerData, isBusinessUnits, isSubsidiaries]);
  const isBooleanMetric = useMemo(() => metric.metricType === QuestionType_Enum_.YesNo_, [metric]);
  const hasNoChildrenMetrics = useMemo(() => {
    if (isBooleanMetric) {
      return true;
    }
    return !metric.childrenMetrics.length;
  }, [metric, isBooleanMetric]);

  useEffect(() => {
    if (!answerId) {
      upsertAnswer({
        variables: {
          objects: {
            reportingUnitId,
            assessmentId: esrsAssessmentId,
            metricRef: metric.reference,
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMetricAnswerIdKey_,
                update_columns: [NoteHistory_Update_Column_.MetricAnswerId_],
              },
            },
          },
        },
        refetchQueries: [
          GetMetricAnswerDocumentationByIdDocument_,
          GetSingleEsrsMetricAnswerDocument_,
        ],
      }).then((res) =>
        setMetricAnswerId(res.data?.insert_esrs_Answer?.returning?.[0].id ?? answerId)
      );
    }
  }, [answerId]);

  return (
    <Box h="100%" display="flex" flexGrow="1" flexDirection="column">
      <VStack alignItems="start" width="100%" spacing="10px" padding="24px 20px">
        <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="4px">
          <Typography variant="h3">Governance</Typography>
        </Box>

        <HStack w="100%" py="4px" alignItems="start">
          <Box>
            <Typography variant="body" width="120px">
              Answer
            </Typography>
          </Box>
          <TruncatableText text={datapoint?.value ?? '—'} variant="body" noOfLines={3} />
        </HStack>

        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Last edited
          </Typography>
          <LastEditedBy
            row={row ?? { metric: metric, tags: [] }}
            selectedQuarter={selectedQuarter}
            reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
          />
        </HStack>

        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Owner
          </Typography>
          <MetricOwnerSelect
            row={row ?? { metric: metric, tags: [] }}
            selectedQuarter={selectedQuarter}
            companyReportingUnit={companyReportingUnit}
            assessmentProjectLeader={esrsAssessmentProjectLeader}
            isBorderless={true}
            isGroup={isGroup}
            size="xs"
          />
        </HStack>
      </VStack>

      <VStack alignItems="start" width="100%" spacing="10px" padding="24px 20px" flexGrow="1">
        <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="4px">
          <Typography variant="h3">Source</Typography>
        </Box>

        <HStack w="100%" alignItems="start" py="4px">
          <Typography width="120px" variant="body">
            Type
          </Typography>
          <AnswerSourceTypeTag
            type={answerSourceType}
            calculation={calculation}
            isBusinessUnits={isBusinessUnits}
            isQuarterly={isQuarterly}
            isSubsidiaries={isSubsidiaries}
            subMetrics={subMetrics}
          />
        </HStack>

        <HStack w="100%" alignItems="start">
          <Box py="4px">
            <Typography width="120px" variant="body">
              Documentation
            </Typography>
          </Box>
          {attachmentBox && (
            <Skeleton isLoaded={!documentationLoading}>
              <MetricDocumentationAttachments attachmentBox={attachmentBox} />
            </Skeleton>
          )}
        </HStack>

        <HStack w="100%" alignItems="start">
          <Box py="4px">
            <Typography width="120px" variant="body">
              Notes
            </Typography>
          </Box>
          {noteHistory && (
            <Skeleton isLoaded={!documentationLoading}>
              <MetricDocumentationNotes noteHistory={noteHistory} />
            </Skeleton>
          )}
        </HStack>
      </VStack>

      {answerData && showAnswers && hasNoChildrenMetrics && (
        <VStack alignItems="start" spacing="12px" padding="20px 24px" width="100%" flexGrow="1">
          <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="4px">
            <Typography variant="h3">
              {isGroup ? `Subsidiaries' answers` : `Business units' answers`}
            </Typography>
          </Box>
          {!isNumeric && (
            <VStack
              pb="16px"
              borderBottom="1px solid"
              borderColor="border.decorative"
              width="100%"
              spacing="6px"
            >
              <DetailsCard
                title={'Summary'}
                author={answerData?.answer?.datapoints?.[0]?.author?.displayName ?? username}
                isChild={false}
                choices={[]}
              />
              <Typography variant="body" color="text.hint">
                {isGroup
                  ? 'Use subsidiaries’ answers to write an answer for this data point.'
                  : 'Use business units’ answers to write an answer for this data point.'}
              </Typography>
              <AnswersSummarizer
                answersData={answerData}
                metric={metric}
                companyReportingUnit={companyReportingUnit}
                generatedSummaryStatus={generatedSummaryStatus}
                setGeneratedSummaryStatus={setGeneratedSummaryStatus}
              />
            </VStack>
          )}

          <VStack alignItems="start" width="100%" spacing="16px">
            {isGroup
              ? answerData?.subsidiaries?.map((sub) => (
                  <DetailsCard
                    key={sub.subsidiary.id}
                    title={sub.subsidiary.company.name}
                    author={sub.answer?.datapoints?.[0]?.author?.displayName ?? ''}
                    answer={sub.answer?.datapoints?.[0]?.value}
                    subsidiary={sub.subsidiary}
                    metricRef={answerData.metricRef}
                    isGroup={isGroup}
                    isNumeric={isNumeric}
                    choices={sub.answer?.datapoints?.[0]?.datapointChoices.map(
                      (c) => c.choice.title
                    )}
                  />
                ))
              : answerData?.reportingUnits?.map((ru) => (
                  <DetailsCard
                    key={ru.reportingUnit.id}
                    title={ru.reportingUnit.name}
                    author={ru.answer?.datapoints?.[0]?.author?.displayName ?? ''}
                    answer={ru.answer?.datapoints?.[0]?.value}
                    metricRef={answerData.metricRef}
                    reportingUnitId={ru.reportingUnit.id}
                    isGroup={isGroup}
                    isNumeric={isNumeric}
                    choices={ru.answer?.datapoints?.[0]?.datapointChoices.map(
                      (c) => c.choice.title
                    )}
                  />
                ))}
          </VStack>
        </VStack>
      )}
    </Box>
  );
};
