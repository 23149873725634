import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useEsrsAssessmentQuery } from 'models';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { EsrsReportStandardSwitch, ReportAnswers } from './Report.types';
import { RenderOnViewportEntry } from './ReportUtils';
import { BackToTopButton } from 'Atoms/BackToTopButton';
import { BottomStandardSwitcher } from './ReportComponents/BottomStandardSwitcher';

const DrCard = React.lazy(() => import('./ReportComponents/DrCard'));

export const ReportStandardPage = ({
  standardIndex,
  setStandardIndex,
  standards,
  reportRef,
  reportAnswers,
  isPreview,
}: {
  standardIndex: number;
  setStandardIndex: (val: number) => void;
  standards?: EsrsReportStandardSwitch[];
  reportAnswers: ReportAnswers;
  reportRef: RefObject<HTMLDivElement>;
  isPreview: boolean;
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const { t } = useTranslation('esrs');
  const [searchParams] = useSearchParams();
  const urlDrRef = useMemo(() => searchParams.get('drRef'), [searchParams]);
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const handleScroll = () => {
    setShowBackToTop((reportRef.current?.scrollTop ?? 0) > 0);
  };

  const { data: assessment, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId || standardIndex !== 0,
  });

  const selectedStandard = useMemo(() => standards?.[standardIndex], [standards, standardIndex]);

  const reportTitle = useMemo(
    () =>
      `${
        t('assessment.report.reportTitle') +
        ' for ' +
        assessment?.esrsAssessment?.company.name +
        ' ' +
        assessment?.esrsAssessment?.reportingYear
      }`,
    [assessment]
  );

  const isFirstInCategory = useMemo(
    () =>
      standards
        ?.filter((std) => std.category === selectedStandard?.category)
        .findIndex((std) => std.title === selectedStandard?.title) === 0,
    [standards, selectedStandard]
  );

  useEffect(() => {
    reportRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [standardIndex]);

  useEffect(() => {
    if (urlDrRef) {
      const element = document.getElementById(urlDrRef);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [urlDrRef, standardIndex, standards]);

  return (
    <Box
      overflowY="scroll"
      height="100%"
      minW="780px"
      flex={1}
      maxWidth="1088px"
      pt="16px"
      onScroll={handleScroll}
      paddingInline="8px"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        scrollbarWidth: 'none',
      }}
      ref={reportRef}
    >
      <VStack
        minW="60%"
        maxWidth="1088px"
        p="72px 48px"
        shadow="interactive.accent"
        background="bg.elevated.interactive"
        alignItems="start"
        borderRadius="12px"
        gap="24px"
        minH=" 1200px"
      >
        {showBackToTop && <BackToTopButton containerRef={reportRef} />}
        {standardIndex === 0 && (
          <Skeleton isLoaded={!loadingAssessment}>
            <Typography variant="d3" whiteSpace="normal">
              {reportTitle}
            </Typography>
          </Skeleton>
        )}
        {isFirstInCategory && <Typography variant="d3">{selectedStandard?.category}</Typography>}
        <Typography variant="h1">{selectedStandard?.title}</Typography>
        {selectedStandard?.disclosureRequirementRefs.map((dr) => (
          <Box id={dr.key} w="100%">
            <RenderOnViewportEntry threshold={0} key={dr.key}>
              <DrCard
                isPreview={isPreview}
                dr={dr}
                reportAnswers={reportAnswers}
                urlMetricRef={urlMetricRef ?? ''}
                standard={selectedStandard}
              />
            </RenderOnViewportEntry>
          </Box>
        ))}
      </VStack>
      <BottomStandardSwitcher
        setStandardIndex={setStandardIndex}
        standards={standards}
        standardIndex={standardIndex}
      />
    </Box>
  );
};
