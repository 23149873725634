import { saveAs } from 'file-saver';
import { DocumentFileIcon } from 'Tokens/Icons/FileIcons';
import { Box, HStack, Spinner, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { Packer } from 'docx';
import { EsrsDocumentCreator } from './esrs-report-generator';
import { useTranslation } from 'utils/translation';
import { useEsrsReportData } from '../Report.hooks';
import { useEffect, useState, MouseEvent } from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { EsrsReportData } from '../Report.types';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';

const GeneratingReportView = ({
  onDone,
  data,
  loading,
}: {
  onDone: () => void;
  data: EsrsReportData;
  loading: boolean;
}) => {
  const { t } = useTranslation('esrs');

  const { company } = useCurrentCompany();

  const generateReport = async () => {
    const esrsDocumentCreator = new EsrsDocumentCreator();

    const doc = await esrsDocumentCreator.create(
      {
        data,
      },
      company?.currency ?? 'EUR'
    );

    Packer.toBlob(doc)
      .then((blob) => {
        saveAs(blob, `esrs-report.docx`);
      })
      .then(() => {
        onDone();
      });
  };

  useEffect(() => {
    if (!loading) {
      generateReport();
    }
  }, [loading]);

  return (
    <HStack w="100%" spacing="10.33px" cursor="default">
      <Box position="relative">
        <Spinner boxSize="16px" position="absolute" opacity={0.46} top="22%" left="15%" />
        <Box opacity={0.1}>
          <DocumentFileIcon />
        </Box>
      </Box>

      <VStack alignItems="start" spacing="0px" opacity={0.46}>
        Generating:
        <Typography variant="bodyStrong">{t('esrs:assessment.report.name')}</Typography>
        <Typography variant="detail">{t('esrs:assessment.report.wordDoc')}</Typography>
      </VStack>
    </HStack>
  );
};

export const EsrsReportDocsGenerator = () => {
  const { data, loading } = useEsrsReportData();

  const [isClicked, setIsClicked] = useState(false);

  const { t } = useTranslation('esrs');

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsClicked(true);

    mixpanel.track(TRACKING_EVENTS.ESRS.REPORT.WORD_REPORT_DOWNLOAD, {
      reportType: 'Word Document',
      reportName: t('esrs:assessment.report.name'),
    });
  };

  if (isClicked) {
    return (
      <GeneratingReportView onDone={() => setIsClicked(false)} data={data} loading={loading} />
    );
  }

  return (
    <HStack w="100%" spacing="10.33px" onClick={handleClick}>
      <DocumentFileIcon />
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong">{t('esrs:assessment.report.name')}</Typography>
        <Typography variant="detail">{t('esrs:assessment.report.wordDoc')}</Typography>
      </VStack>
    </HStack>
  );
};
