import { MutationTuple } from '@apollo/client';
import { useUserData } from '@nhost/react';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { omit, pick } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Comment,
  Thread,
  useUpsertThreadMutation,
  useRemoveCommentMutation,
  useThreadSubscription,
  Answer,
  CompanyAssessmentDocument_,
  BAssessment,
  useUpsertActivityReportsMutation,
  ActivityRef,
  useAllActivitiesQuery,
  useAddAnswerMutation,
  AnswerUpdate,
  Answer_Insert_Input_,
  ThreadSubscriber_Constraint_,
  ThreadSubscriber_Update_Column_,
  ThreadSubscriber_Arr_Rel_Insert_Input_,
  Thread_Constraint_,
  Thread_Update_Column_,
  ActivityAssessmentDocument_,
  Financials_Constraint_,
  GetFinancialsDocument_,
  BusinessUnitDocument_,
  BusinessUnitQuery_,
  BusinessUnitQueryVariables_,
  useUpsertBusinessUnitMutation,
  BusinessUnitAssessment_Insert_Input_,
  useCompanyAssessmentQuery,
  BusinessUnitsDocument_,
  ActivityAssessmentAnswersDocument_,
  ActivityAssessmentDetails,
  useAddNoteMutation,
  Note_Insert_Input_,
  DocumentationFile,
  AttachmentBox,
  AttachmentBox_Constraint_,
  NoteHistory_Constraint_,
  Financials_Update_Column_,
  AttachmentBox_Update_Column_,
  NoteHistory_Update_Column_,
  useAddAnswerWithoutDataMutation,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useLatest } from 'react-use';
import { Tuple } from 'utils';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import {
  escapeKey,
  translateFields,
  useEUDataTranslation,
  useTranslation,
} from 'utils/translation';
import { validate } from 'uuid';
import { createFinancials } from 'utils/financials';
import { useParams } from 'react-router-dom';
import { nhost } from 'utils/nhost';
import { useSaveAttachments } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { useFlagAndUnlockAssessment } from 'containers/Assessments/Assessments.hooks';
import { useGetNewestValidActivityVersion } from 'containers/Assessments/VersioningOfQuestions.hooks';
import { hasSupportRole } from 'utils/users.js';

const GENERAL_ACTIVITY_REF = '0.0';

export function useCommentThread({
  id,
  ...props
}: Partial<Pick<Thread, 'id' | 'companyId' | 'answerId'>>) {
  const user = useUserData();
  const [upsertThread] = useUpsertThreadMutation();
  const [deleteCommentMutation] = useRemoveCommentMutation();
  const { data: threadResult, loading } = useThreadSubscription({
    skip: !validate(id),
    variables: { id },
  });
  const thread = threadResult?.thread;
  const propsRef = useLatest(props);
  const addComment = useCallback(
    (comment: string) => {
      return upsertThread({
        variables: {
          thread: {
            id,
            ...propsRef.current,
            ...pick(thread, 'companyId', 'answerId'),
            comments: {
              data: [
                {
                  data: comment,
                  authorId: user?.id,
                },
              ],
            },
            subscribers: undefined,
          },
        },
      });
    },
    [upsertThread, thread]
  );

  const deleteComment = useCallback(
    (commentId: Comment['id']) => deleteCommentMutation({ variables: { id: commentId } }),
    [deleteCommentMutation]
  );

  return { addComment, deleteComment, thread: threadResult?.thread, loading };
}

export function wrap<TData, TVariables>(mutation: MutationTuple<TData, TVariables>) {
  return { mutate: mutation[0], ...mutation[1], isLoading: mutation[1].loading };
}

export function useToggleActivity(bAssessment: BAssessment) {
  const [flagAndUnlockAssessment] = useFlagAndUnlockAssessment();
  const { cAssessmentId } = useParams();
  const { data: cAssessmentData } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: cAssessmentId ?? '',
    },
    skip: !cAssessmentId,
  });
  const { companyId } = useCurrentCompanyId();
  const upsertActivityReport = wrap(
    useUpsertActivityReportsMutation({
      refetchQueries: [CompanyAssessmentDocument_, GetFinancialsDocument_],
      awaitRefetchQueries: true,
    })
  );

  const { getNewestValidActivityVersion } = useGetNewestValidActivityVersion();

  const wrapped = useCallback(
    async (
      enabled: boolean,
      activityRef: ActivityRef = GENERAL_ACTIVITY_REF,
      versionNumber?: number
    ) => {
      mixpanel.track(TRACKING_EVENTS.BUSINESS_UNITS.MANAGE_ACTIVITIES, {
        companyId,
        action: enabled ? 'add' : 'remove',
      });
      const newestVersionNumber = await getNewestValidActivityVersion(
        activityRef,
        cAssessmentData?.companyAssessment?.startDate,
        cAssessmentData?.companyAssessment?.id
      );
      return upsertActivityReport
        .mutate({
          variables: {
            activityReports: [
              {
                bAssessmentId: bAssessment?.id,
                activityRef,
                activityVersionNumber: versionNumber ?? newestVersionNumber,
                deletedAt: enabled ? null : 'now()',
                createdAt: enabled ? 'now()' : undefined, // For proper ordering if it exists already
                financials: {
                  data: { ...createFinancials(0, 0, 0, 0, 0), updatedAt: 'now()' },
                  on_conflict: {
                    constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                    update_columns: [Financials_Update_Column_.UpdatedAt_],
                  },
                },
                attachmentBox: {
                  data: {},
                  on_conflict: {
                    constraint: AttachmentBox_Constraint_.AttachmentBoxActivityReportIdKey_,
                    update_columns: [],
                  },
                },
                noteHistory: {
                  data: {},
                  on_conflict: {
                    constraint: NoteHistory_Constraint_.NoteHistoryActivityReportIdKey_,
                    update_columns: [],
                  },
                },
              },
            ],
          },
        })
        .then(() => flagAndUnlockAssessment(cAssessmentData?.companyAssessment));
    },
    [upsertActivityReport, bAssessment, getNewestValidActivityVersion]
  );
  return Tuple(wrapped, {
    loading: upsertActivityReport.isLoading,
  });
}

export function useAllActivities() {
  const user = useUserData();
  const { data } = useAllActivitiesQuery({
    variables: {
      showHidden: hasSupportRole(user?.roles),
    },
  });
  const allActivities = data?.allActivities ?? [];
  const { t } = useEUDataTranslation('activity');
  return useMemo(
    () =>
      allActivities.map((a) =>
        translateFields(a, ['name', 'description'], t, escapeKey(a.reference))
      ),
    [allActivities, t]
  );
}

export function getSubscribeCurrentUserInput() {
  return {
    // Add current user to thread subscribers
    data: [
      {
        // userId and isSubscribed=True is set by backend automatically
      },
    ],
    on_conflict: {
      constraint: ThreadSubscriber_Constraint_.ThreadSubscriberThreadIdUserIdC1ed612aUniq_,
      update_columns: [ThreadSubscriber_Update_Column_.IsSubscribed_],
    },
  } as ThreadSubscriber_Arr_Rel_Insert_Input_;
}

export function useSaveAnswerMutation() {
  const [mutate, result] = useAddAnswerMutation();
  const { cAssessmentId } = useParams();
  const { data: cAssessmentData } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: cAssessmentId ?? '',
    },
    skip: !cAssessmentId,
    fetchPolicy: 'cache-only',
  });

  const [flagAndUnlockAssessment] = useFlagAndUnlockAssessment();
  const user = useUserData();
  const modifiedMutate = useCallback(
    async (
      newAnswer: AnswerUpdate & {
        activityReport?: ActivityAssessmentDetails['activityAssessment'];
      },
      oldAnswer?: Answer,
      blockUnlockAssessment = false
    ) => {
      const data: Answer_Insert_Input_ = omit(
        {
          ...pick(oldAnswer, 'flagged', 'threadId', 'data'),
          ...newAnswer,
        },
        'attachments',
        'thread',
        'report',
        'note'
      ) as Answer_Insert_Input_;
      data.noteHistory = {
        data: {},
        on_conflict: {
          constraint: NoteHistory_Constraint_.NoteHistoryAnswerIdKey_,
          update_columns: [],
        },
      };
      data.attachmentBox = {
        data: {},
        on_conflict: {
          constraint: AttachmentBox_Constraint_.AttachmentBoxAnswerIdKey_,
          update_columns: [],
        },
      };

      if (newAnswer.thread) {
        data.thread = {
          data: {
            ...newAnswer.thread,
            comments: { data: newAnswer.thread.comments },
            subscribers: getSubscribeCurrentUserInput(),
          },
          on_conflict: {
            constraint: Thread_Constraint_.ThreadAnswerIdKey_,
            update_columns: [Thread_Update_Column_.AnswerId_],
          },
        };
      }
      try {
        await mutate({
          variables: { answer: data },
          refetchQueries: [ActivityAssessmentDocument_, ActivityAssessmentAnswersDocument_],
          awaitRefetchQueries: true,
        });
        if (!blockUnlockAssessment)
          await flagAndUnlockAssessment(cAssessmentData?.companyAssessment);
      } catch (e) {
        console.error('FAILED', e);
      }
    },
    [mutate, user, cAssessmentData]
  );
  return Tuple(modifiedMutate, result);
}

export function useSaveAnswerNoteMutation() {
  const [addNote] = useAddNoteMutation();
  const [newAnswer] = useAddAnswerWithoutDataMutation();

  const user = useUserData();
  return useCallback(
    async (
      note: string | undefined,
      reportId: string,
      questionId: string,
      noteHistoryId?: string
    ) => {
      const answerDoesNotExist = !noteHistoryId;
      let updatedNoteHistoryId = noteHistoryId;

      if (answerDoesNotExist) {
        const data: Answer_Insert_Input_ = {
          data: null,
          answeredById: user?.id,
          reportId: reportId,
          questionId: questionId,
          noteHistory: {
            data: {},
            on_conflict: {
              constraint: NoteHistory_Constraint_.NoteHistoryAnswerIdKey_,
              update_columns: [],
            },
          },
          attachmentBox: {
            data: {},
            on_conflict: {
              constraint: AttachmentBox_Constraint_.AttachmentBoxAnswerIdKey_,
              update_columns: [],
            },
          },
        };

        await newAnswer({
          variables: {
            answer: data,
          },
        }).then((answerResult) => {
          updatedNoteHistoryId = answerResult.data?.answer?.noteHistory?.id;
        });
      }

      const noteData: Note_Insert_Input_ = {
        body: note,
        noteHistoryId: updatedNoteHistoryId,
        authorId: user?.id,
      };

      try {
        await addNote({
          variables: {
            noteInput: noteData,
          },
          refetchQueries: [ActivityAssessmentDocument_, ActivityAssessmentAnswersDocument_],
          awaitRefetchQueries: true,
        });
      } catch (e) {
        console.error('FAILED', e);
      }
    },
    [addNote, user]
  );
}

export function useSaveAnswerAttachmentMutation() {
  const saveAttachments = useSaveAttachments();
  const [newAnswer] = useAddAnswerWithoutDataMutation();

  const user = useUserData();

  return useCallback(
    async (
      filesToAttach: DocumentationFile[],
      reportId: string,
      questionId: string,
      attachmentBox?: AttachmentBox
    ) => {
      const answerDoesNotExist = !attachmentBox;
      let updatedAttachmentBox = attachmentBox;

      if (answerDoesNotExist) {
        const data: Answer_Insert_Input_ = {
          data: null,
          answeredById: user?.id,
          reportId: reportId,
          questionId: questionId,
          noteHistory: {
            data: {},
            on_conflict: {
              constraint: NoteHistory_Constraint_.NoteHistoryAnswerIdKey_,
              update_columns: [],
            },
          },
          attachmentBox: {
            data: {},
            on_conflict: {
              constraint: AttachmentBox_Constraint_.AttachmentBoxAnswerIdKey_,
              update_columns: [],
            },
          },
        };

        await newAnswer({
          variables: {
            answer: data,
          },
        }).then((answerResult) => {
          updatedAttachmentBox = answerResult.data?.answer?.attachmentBox ?? undefined;
        });
      }

      if (updatedAttachmentBox) {
        await saveAttachments(filesToAttach, updatedAttachmentBox, [
          ActivityAssessmentDocument_,
          ActivityAssessmentAnswersDocument_,
        ]);
      }
    },
    [saveAttachments, user]
  );
}

export function useDuplicateBusinessUnit() {
  const { cAssessmentId } = useParams();
  const toast = useToast();
  const [saveBusinessUnit] = useUpsertBusinessUnitMutation();
  const [flagAndUnlockAssessment] = useFlagAndUnlockAssessment();
  const { t } = useTranslation('common');
  return useCallback(
    async (
      businessUnitId: string,
      name: string,
      options: {
        withNotes: boolean;
        withAttachments: boolean;
        withAnswers: boolean;
      } = {
        withNotes: true,
        withAttachments: true,
        withAnswers: true,
      }
    ) => {
      const { data } = await nhost.graphql.request<BusinessUnitQuery_, BusinessUnitQueryVariables_>(
        BusinessUnitDocument_,
        {
          id: businessUnitId,
        }
      );
      const businessUnitDataRaw = data?.businessUnit;

      if (businessUnitDataRaw) {
        // Remove financials from duplicated business units
        const bAssessments: BusinessUnitAssessment_Insert_Input_[] =
          businessUnitDataRaw.bAssessments.map((bA) => ({
            ...omit(bA, 'cAssessment', 'id'),
            duplicatedFrom: bA.id,
            activityReports: {
              data: bA.activityReports.map((ar) => ({
                activityRef: ar.activityRef,
                activityVersionNumber: ar.activityVersionNumber,
                answers: {
                  data: ar.answers.map((answer) => ({
                    ...answer,
                    data: options.withAnswers ? answer.data : null,
                    noteHistory: {
                      data: {
                        notes: {
                          data: options?.withNotes
                            ? (answer.noteHistory?.notes?.map((note) => ({
                                body: note.body,
                                authorId: note.authorId,
                                updatedAt: note.updatedAt,
                              })) ?? [])
                            : [],
                        },
                      },
                    },
                    attachmentBox: {
                      data: {
                        attachments: {
                          data: options?.withAttachments
                            ? (answer.attachmentBox?.attachments ?? [])?.map((at) => ({
                                fileId: at.fileId,
                              }))
                            : [],
                        },
                      },
                    },
                  })),
                },
                attachmentBox: {
                  data: {
                    attachments: {
                      data: options?.withAttachments
                        ? (ar?.attachmentBox?.attachments ?? [])?.map((at) => ({
                            fileId: at.fileId,
                          }))
                        : [],
                    },
                  },
                  on_conflict: {
                    constraint: AttachmentBox_Constraint_.AttachmentBoxActivityReportIdKey_,
                    update_columns: [AttachmentBox_Update_Column_.ActivityReportId_],
                  },
                },
                noteHistory: {
                  data: {
                    notes: {
                      data: options?.withNotes
                        ? (ar?.noteHistory?.notes ?? [])?.map((note) => ({
                            body: note.body,
                            authorId: note.authorId,
                            updatedAt: note.updatedAt,
                          }))
                        : [],
                    },
                  },
                  on_conflict: {
                    constraint: NoteHistory_Constraint_.NoteHistoryActivityReportIdKey_,
                    update_columns: [NoteHistory_Update_Column_.ActivityReportId_],
                  },
                },
                financials: {
                  data: createFinancials(0, 0, 0, 0, 0),
                },
              })),
            },
          }));
        try {
          await saveBusinessUnit({
            variables: {
              businessUnit: {
                id: undefined,
                name: name,
                contactPersonId: businessUnitDataRaw?.contactPersonId,
                companyId: businessUnitDataRaw?.companyId,
                labels: businessUnitDataRaw?.labels,
                bAssessments: {
                  data: bAssessments.filter(
                    (bAssessment) => bAssessment.cAssessmentId === cAssessmentId
                  ),
                },
              },
            },
            refetchQueries: [
              CompanyAssessmentDocument_,
              GetFinancialsDocument_,
              BusinessUnitsDocument_,
            ],
            awaitRefetchQueries: true,
          });

          await Promise.all(
            businessUnitDataRaw.bAssessments.map((bA) => {
              flagAndUnlockAssessment(bA.cAssessment);
            })
          );
          toast({
            text: `${t('common:screening.success', { name: name })}`,
          });
        } catch (e) {
          console.error(e);
          toast({
            text: `${t('common:screening.fail', { name: name })}`,
            variant: 'danger',
          });
        }
      }
    },
    [saveBusinessUnit]
  );
}
